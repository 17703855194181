
import {
  defineComponent, onBeforeMount, reactive,
} from 'vue';
import { useAppBase } from '@/core/composables/AppBase';
import ComunicacaoApi from '@/core/components/UI/ComunicacaoApi.vue';
import IndicadorNumero from '@/core/components/UI/IndicadorNumero.vue';
import { IDTODashboardIndicadores } from '@/models/DTO/Dashboard/IDTODashboardIndicadores';
import ServicoDashboard from '@/servicos/ServicoDashboard';

export default defineComponent({
  name: 'Dashboard',
  components: {
    ComunicacaoApi,
    IndicadorNumero,
  },
  setup() {
    const {
      appBase,
    } = useAppBase();
    const servicoDashboard = new ServicoDashboard();
    const state = reactive({
      indicadoresCadastros: {} as IDTODashboardIndicadores,
    });

    function limparDados() {
      state.indicadoresCadastros.produtosAtivos = 0;
      state.indicadoresCadastros.clientesAtivos = 0;
      state.indicadoresCadastros.formasEntregasAtivas = 0;
      state.indicadoresCadastros.formasPagamentosAtivas = 0;
    }

    onBeforeMount(async () => {
      limparDados();
      appBase.carregando = true;
      state.indicadoresCadastros = await servicoDashboard.obterIndicadoresDashBoard();
      appBase.carregando = false;
    });

    return {
      appBase,
      state,
    };
  },
});
